import { AfterViewInit, Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';

import { Meeting } from '../meeting';
import { MeetingService } from '../meeting.service';
import { MessageService } from '../message.service';
import { MeetingDataSource } from './meetings-datasource';
import { ProgressService } from '../progress.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MeetingsComponent implements AfterViewInit, OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Meeting>;
  dataSource: MeetingDataSource;
  emptyDataSource: MeetingDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['title', 'organizer', 'status'];

  meetings: Meeting[];
  selectedMeeting: Meeting;
  isDataLoaded = false;
  progressRef: any;
  meetingsType = 'upcoming';
  meetingStatusFilter = 'all';
  meetingDateFilter = 'current';
  meetingPastDateFilter = 'last';

  summaryFilterTitle = 'My Upcoming Meetings';
  generalFilterTitle = 'Upcoming Meetings';
  fullFilterDescription = 'Showing all my upcoming meetings without filter'

  constructor(
    private progressService: ProgressService,
    private meetingService: MeetingService,
    private messageService: MessageService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.emptyDataSource = new MeetingDataSource();
    this.emptyDataSource.data = [];
    this.dataSource = new MeetingDataSource();
  }

  ngAfterViewInit() {
    this.table.dataSource = this.emptyDataSource;
    this.updateMeetingsList();
  }

  filterButtonClick(): boolean {
    return false;
  }

  showUpcomingMeetings() {
    this.meetingsType = 'upcoming';
    this.displayedColumns = ['title', 'organizer', 'status'];
    this.updateMeetingsList();
  }

  showPastMeetings() {
    this.meetingsType = 'past';
    this.displayedColumns = ['title', 'organizer'];
    this.updateMeetingsList();
  }

  showMeetingSeries() {
    this.meetingsType = 'series';
    this.displayedColumns = ['title', 'organizer'];
    this.updateMeetingsList();
  }

  updateMeetingsList(): void {
    this.isDataLoaded = false;
    this.table.dataSource = null;
    setTimeout(() => this.getMeetings(), 0);
  }

  getMeetings(): void {
    const filter = this.meetingService.searchTemplate();
    filter.type = this.meetingsType;
    if (this.meetingsType == 'upcoming') {
      filter.status = this.meetingStatusFilter;
    }
    if (this.meetingsType == 'past') {
    }
    this.meetingService.searchMeetings(filter)
      .subscribe(meetings => { this.meetings = meetings; this.refresh(); });
  }

  refresh(): void {
    this.table.dataSource = this.emptyDataSource;
    this.paginator.pageIndex = 0;
    this.dataSource = new MeetingDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.meetings;
    this.table.dataSource = this.dataSource;
    this.isDataLoaded = true;
    // if (this.progressRef) {
    //   this.progressService.detach(this.progressRef);
    //   this.progressRef = null;
    // }
  }

  onSelect(meeting: Meeting): void {
    this.messageService.add(`selected meeting id=${meeting.id}`);
    this.selectedMeeting = meeting;
  }

  add(title: string): void {
    title = title.trim();
    if (!title) { return; }
    this.meetingService.addMeeting({ title } as Meeting)
      .subscribe(meeting => {
        this.meetings.push(meeting);
      });
  }

  delete(meeting: Meeting): void {
    this.meetings = this.meetings.filter(m => m !== meeting);
    this.meetingService.deleteMeeting(meeting).subscribe();
  }


}
