<div class="container" fxLayout="column" fxLayoutAlign="space-around center">
  <h1 style="padding-top:16px;">MeetingBooster PWA</h1>
  <p class="text-block">
    Simple mock-application for MeetingBooster to visually describe what is Progressive Web App
    and how MeetingBooster mobile app may look like.
  </p>
  <p class="text-block">
    Try opening the app on different devices (phone, tablet) with small or big screen size and portrait or landscape orioentation. Notice how UI adopts to it.
  </p>
</div>
