import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Meeting } from './meeting';
import { Agenda } from './agenda';
import { Task } from './task';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class InMemoryDataService implements InMemoryDbService {
  nextId : number = 100;
  createDb() {
    const meetings = [
      {id: 100, type: 'upcoming', status:'pending', group: 'HeadQuaters', title: 'Restart the Universe', organizer: 'Rocky' },
      {id: 101, type: 'upcoming', status:'declined', group: 'HeadQuaters', title: 'Preparing for Earth Crush', organizer: 'Rocky'  },
      {id: 102, type: 'upcoming', status:'accepted', group: 'HeadQuaters', title: 'Keep Calm and Develop', organizer: 'Calman'  },
      {id: 103, type: 'upcoming', status:'accepted', group: 'HeadQuaters', title: 'MeetingBooster mobile app version development plans for 2020 year', organizer: 'Calman' },
      {id: 104, type: 'upcoming', status:'accepted', group: 'HeadQuaters', title: 'Working at home - recomendations', organizer: 'Calman'  },
      {id: 105, type: 'upcoming', status:'accepted', group: 'HeadQuaters', title: 'What to do to be happy', organizer: 'Sadman'  },
      {id: 106, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Mariya', title: 'employee Training Plan 2020' },
      {id: 107, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Ella', title: 'staff motivation program' },
      {id: 108, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Magda', title: 'effectiveness of the program the best result' },
      {id: 109, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Jack', title: 'expansion of sales markets in EU' },
      {id: 110, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Henry', title: 'online sales comparison chart' },
      {id: 111, type: 'upcoming', status: 'pending', group: 'Production', organizer: 'Olivia', title: 'mobile version of the program QUIK' },
      {id: 112, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Ella', title: 'team work training' },
      {id: 113, type: 'past', status: 'pending', group: 'HR', organizer: 'Mariya', title: 'introduction program for new employee' },
      {id: 114, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Jack', title: 'loyalty system for wholesale customers' },
      {id: 115, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Magda', title: 'benefits of online performance evaluation' },
      {id: 116, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Jack', title: 'online presentation of the program BSM to customers' },
      {id: 117, type: 'past', status: 'pending', group: 'Sales', organizer: 'Jack', title: 'sales development program' },
      {id: 118, type: 'upcoming', status: 'pending', group: 'HR', organizer: 'Magda', title: 'annual performance assessment' },
      {id: 119, type: 'upcoming', status: 'pending', group: 'Production', organizer: 'Joseph', title: 'setting up an online payment module' },
      {id: 120, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Henry', title: 'new sales channels' },
      {id: 121, type: 'upcoming', status: 'pending', group: 'Sales', organizer: 'Henry', title: 'product testing in different markets' },
      {id: 122, type: 'past', status: 'pending', group: 'Sales', organizer: 'Henry', title: 'operational work with clients 24/7' },
      {id: 123, type: 'upcoming', status: 'pending', group: 'Production', organizer: 'Peter', title: 'implementation of speech recognition' },
      {id: 124, type: 'upcoming', status: 'pending', group: 'Production', organizer: 'Peter', title: 'weekly meeting on project status' },
    ];
    const agendas = [
      { id: 200, title: 'New Product' },
      { id: 201, title: 'More Sales' },
    ];
    const tasks = [
      { id: 300, title: 'task 1' },
      { id: 301, title: 'task 2' },
      { id: 302, title: 'task 3' },
      { id: 303, title: 'task 4' },
      { id: 304, title: 'task 5' },
    ];
    return {meetings, agendas, tasks};
  }
  genId<T extends Meeting | Agenda | Task>(myTable: T[]): number {
    return ++ this.nextId;
    // return myTable.length > 0 ? Math.max(...myTable.map(t => t.id)) + 1 : 11;
  }
}
