<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>MeetingBooster</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="/meetings">Meetings</a>
      <a mat-list-item href="/tasks">Tasks</a>
      <a mat-list-item href="/about">About</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>MeetingBooster</span>
    </mat-toolbar>
    <app-table></app-table>
  </mat-sidenav-content>
</mat-sidenav-container>
