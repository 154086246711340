import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { Meeting } from './meeting';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  private meetingsUrl = 'api/meetings';  // URL to web api
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`MeetingService: ${message}`);
  }

  /** GET meetings from the server */
  getMeetings(): Observable<Meeting[]> {
    return this.http.get<Meeting[]>(this.meetingsUrl)
      .pipe(
        tap(_ => this.log('fetched meetings')),
        catchError(this.handleError<Meeting[]>('getMeetings', []))
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      this.messageService.openSnackBar(`${operation} failed: ${error.message}`, 'OK');
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** GET meeting by id. Will 404 if id not found */
  getMeeting(id: number): Observable<Meeting> {
    const url = `${this.meetingsUrl}/${id}`;
    return this.http.get<Meeting>(url).pipe(
      tap(_ => this.log(`fetched meeting id=${id}`)),
      catchError(this.handleError<Meeting>(`getMeeting id=${id}`))
    );
  }

  /* GET meetings whose title contains search term */
  searchTemplate(): Meeting {
    return {type: '', title: '', organizer: '', status: ''} as Meeting;
  }
  searchMeetings(meeting: Meeting): Observable<Meeting[]> {
    var term:string, url = '';
    term = meeting.type.trim();
    if (term && term !== '') { if (url) url += '&'; url += `type=${term}`; }
    term = meeting.status.trim();
    if (term && term !== 'all') { if (url) url += '&'; url += `status=${term}`; }
    term = meeting.title.trim();
    if (term) { if (url) url += '&'; url += `title=${term}`; }
    term = meeting.organizer.trim();
    if (term) { if (url) url += '&'; url += `organizer=${term}`; }
    if (url) { url = '?' + url; }
    return this.http.get<Meeting[]>(`${this.meetingsUrl}/${url}`).pipe(
      tap(x => x.length ?
        this.log(`found meetings matching "${term}"`) :
        this.log(`no meetings matching "${term}"`)),
      catchError(this.handleError<Meeting[]>('searchMeetings', []))
    );
  }

  /** PUT: update the meeting on the server */
  updateMeeting(meeting: Meeting): Observable<any> {
    return this.http.put(this.meetingsUrl, meeting, this.httpOptions).pipe(
      tap(_ => this.log(`updated meeting id=${meeting.id}`)),
      catchError(this.handleError<any>('updateMeeting'))
    );
  }

  /** POST: add a new meeting to the server */
  addMeeting(meeting: Meeting): Observable<Meeting> {
    return this.http.post<Meeting>(this.meetingsUrl, meeting, this.httpOptions).pipe(
      tap((newMeeting: Meeting) => this.log(`added meeting w/ id=${newMeeting.id}`)),
      catchError(this.handleError<Meeting>('addMeeting'))
    );
  }

  /** DELETE: delete the meeting from the server */
  deleteMeeting(meeting: Meeting | number): Observable<Meeting> {
    const id = typeof meeting === 'number' ? meeting : meeting.id;
    const url = `${this.meetingsUrl}/${id}`;

    return this.http.delete<Meeting>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted meeting id=${id}`)),
      catchError(this.handleError<Meeting>('deleteMeeting'))
    );
  }

}
