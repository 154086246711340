import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {MeetingsComponent} from './meetings/meetings.component';
import {TableComponent} from './table/table.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';


const routes: Routes = [
  { path: '', redirectTo: '/about', pathMatch: 'full' },

  { path: 'about', component: AboutComponent },
  { path: 'help', component: AboutComponent },

  { path: 'meetings', component: MeetingsComponent },
  { path: 'tasks', component: UnderConstructionComponent },
  { path: 'dashboard', component: UnderConstructionComponent },

  { path: 'profile', component: UnderConstructionComponent },
  { path: 'admin', component: UnderConstructionComponent },
  { path: 'logout', component: UnderConstructionComponent },

  { path: 'new-meeting', component: UnderConstructionComponent },
  { path: 'new-meeting-recurring', component: UnderConstructionComponent },
  { path: 'new-meeting-series', component: UnderConstructionComponent },
  { path: 'new-task', component: UnderConstructionComponent },

  { path: 'under-construction', component: UnderConstructionComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
