<!--<mat-toolbar>-->
<!--  <span class="toolbar-spacer"></span>-->
<!--  <button mat-raised-button>{{meetingsType}}</button>-->
<!--  <span class="toolbar-spacer"></span>-->
<!--</mat-toolbar>-->
<nav mat-tab-nav-bar mat-stretch-tabs>
  <a mat-tab-link [active]="meetingsType=='upcoming'" (click)="showUpcomingMeetings()">Upcoming<span class="hide-xs">&nbsp;Meetings</span></a>
  <a mat-tab-link [active]="meetingsType=='past'" (click)="showPastMeetings()">Past<span class="hide-xs">&nbsp;Meetings</span></a>
  <a mat-tab-link [active]="meetingsType=='series'" (click)="showMeetingSeries()"><span class="hide-xs">Meeting&nbsp;</span>Series</a>
</nav>
<br/>
<div [fxHide]="meetingsType!='upcoming'" fxLayout="row" fxLayoutAlign="space-around center">
  <span class="toolbar-spacer"></span>
  <mat-form-field class="filter-bydate">
    <mat-label>Filter by Date</mat-label>
    <mat-select [(value)]="meetingDateFilter" (valueChange)="updateMeetingsList()">
      <mat-option value="current">Next 24h</mat-option>
      <mat-option value="week">This Week</mat-option>
      <mat-option value="month">This Month</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="toolbar-spacer"></span>
  <mat-form-field class="filter-bystatus">
    <mat-label>Filter by Status</mat-label>
    <mat-select [(value)]="meetingStatusFilter" (valueChange)="updateMeetingsList()">
      <mat-option value="all">Show All</mat-option>
      <mat-option value="accepted">Accepted</mat-option>
      <mat-option value="pending">Pending</mat-option>
      <mat-option value="declined">Declined</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="toolbar-spacer"></span>
</div>
<div [fxHide]="meetingsType!='past'" fxLayout="row" fxLayoutAlign="space-around center">
  <span class="toolbar-spacer"></span>
  <mat-form-field class="filter-bypastdate">
    <mat-label>Filter by Date</mat-label>
    <mat-select [(value)]="meetingPastDateFilter" (valueChange)="updateMeetingsList()">
      <mat-option value="last">Last 24h</mat-option>
      <mat-option value="m7days">Last 7 days</mat-option>
      <mat-option value="m30days">Last 30 days</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="toolbar-spacer"></span>
</div>
<!--<div fxHide fxLayout="column">-->
<!--  <mat-expansion-panel>-->
<!--    <mat-expansion-panel-header>-->
<!--      <mat-panel-title>-->
<!--        <span fxHide.gt-xs>{{summaryFilterTitle}}</span>-->
<!--        <span fxHide.xs>{{generalFilterTitle}}</span>-->
<!--      </mat-panel-title>-->
<!--      <mat-panel-description fxHide.xs>{{fullFilterDescription}}</mat-panel-description>-->
<!--    </mat-expansion-panel-header>-->
<!--    <mat-form-field appearance="legacy">-->
<!--      <mat-label>Select kind of a meeting</mat-label>-->
<!--      <mat-select [(value)]="meetingsType" (valueChange)="updateMeetingsList()">-->
<!--        <mat-option value="upcoming">Upcoming Meetings</mat-option>-->
<!--        <mat-option value="past">Past Meetings</mat-option>-->
<!--        <mat-option value="series">Meeting Series</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="legacy">-->
<!--      <mat-label>Select status of a meeting</mat-label>-->
<!--      <mat-select [(value)]="meetingsFilter" (valueChange)="updateMeetingsList()">-->
<!--        <mat-option value="all">Show All</mat-option>-->
<!--        <mat-option value="accepted">Only Accepted</mat-option>-->
<!--        <mat-option value="pending">Only Pending</mat-option>-->
<!--        <mat-option value="declined">Only Declined</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </mat-expansion-panel>-->
<!--</div>-->
<div [fxHide]="isDataLoaded" fxLayout="column" fxLayoutAlign="space-around center">
  <br/><br/>
  <mat-spinner diameter="40"></mat-spinner>
</div>
<!--<mat-list [fxShow]="isDataLoaded && meetings?.length == 0">-->
<!--  <mat-list-item  *ngFor="let meeting of meetings">-->
<!--    <span>{{meeting.title}}</span>-->
<!--    <span>{{meeting.organizer}}</span>-->
<!--    <span>{{meeting.status}}</span>-->
<!--  </mat-list-item>-->
<!--</mat-list>-->
<div [fxShow]="isDataLoaded && meetings?.length == 0">
  <div style="padding-top:40px;color:#999;" fxLayout="column" fxLayoutAlign="space-around center">
    <h1>No Meetings</h1>
<!--    <mat-icon>update</mat-icon>-->
  </div>
</div>
<div [class.cdk-visually-hidden]="!isDataLoaded || meetings?.length == 0" class="mat-elevation-z8">
  <table #table mat-table class="full-width-table" matSort aria-label="Meetings">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Meeting Title</th>
      <td mat-cell *matCellDef="let row">{{row.title}}</td>
    </ng-container>
    <ng-container matColumnDef="organizer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Organizer</th>
      <td mat-cell *matCellDef="let row">{{row.organizer}}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">{{row.status}}</td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let meeting" [attr.colspan]="displayedColumns.length">
        <div class="meeting-detail"
             [@detailExpand]="meeting == selectedMeeting ? 'expanded' : 'collapsed'">
          <div class="meeting-description">
            <h1>{{meeting.title}}</h1>
            <h1>{{meeting.status}}</h1>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let meeting; columns: displayedColumns;"
        class="meeting-row"
        [class.meeting-expanded-row]="selectedMeeting === meeting"
        (click)="selectedMeeting = selectedMeeting === meeting ? null : meeting">
    </tr>
<!--    <tr mat-row *matRowDef="let meeting; columns: ['expandedDetail']" class="meeting-detail-row"></tr>-->
  </table>
  <div [fxShow]="meetings?.length > 10">
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0"
      [pageSize]="100" [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </div>
</div>

