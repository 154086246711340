<mat-menu #mainmenu>
  <button mat-menu-item routerLink="/meetings">
    <mat-icon>people</mat-icon>
    <span>Meetings</span>
  </button>
  <button mat-menu-item routerLink="/tasks">
    <mat-icon>create</mat-icon>
    <span>Tasks</span>
  </button>
  <button mat-menu-item routerLink="/dashboard">
    <mat-icon>show_chart</mat-icon>
    <span>Dashboard</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/profile">
    <mat-icon>settings</mat-icon>
    <span>Profile</span>
  </button>
  <button mat-menu-item routerLink="/admin">
    <mat-icon>security</mat-icon>
    <span>Admin</span>
  </button>
  <button mat-menu-item routerLink="/help">
    <mat-icon>help</mat-icon>
    <span>Help</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/logout">
    <mat-icon>create</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
<mat-menu #addmenu>
  <button mat-menu-item routerLink="/new-meeting">
    <mat-icon>people</mat-icon>
    <span>New Meeting</span>
  </button>
  <button mat-menu-item routerLink="/new-meeting-recurring">
    <mat-icon>people</mat-icon>
    <span>New Recurring Meeting</span>
  </button>
  <button mat-menu-item routerLink="/new-meeting-series">
    <mat-icon>calendar_today</mat-icon>
    <span>New Meeting Series</span>
  </button>
  <button mat-menu-item routerLink="/new-task">
    <mat-icon>create</mat-icon>
    <span>New Task</span>
  </button>
</mat-menu>
<mat-toolbar color="primary">
  <button mat-icon-button [matMenuTriggerFor]="mainmenu" aria-label="Main menu">
    <mat-icon>menu</mat-icon>
  </button>
  <span fxHide.xs>
    <span style="font-weight: 100;">Meeting</span><span style="font-weight: 900;">Booster</span>
  </span>
  <span class="toolbar-spacer"></span>
  <button mat-button routerLink="/meetings">
    <mat-icon>people</mat-icon>
    <span>&nbsp;Meetings</span>
  </button>
  <button mat-button routerLink="/tasks">
    <mat-icon>create</mat-icon>
    <span>&nbsp;Tasks</span>
  </button>
  <button mat-button routerLink="/dashboard" fxHide.xs>
    <mat-icon>show_chart</mat-icon>
    <span>&nbsp;Dashboard</span>
  </button>
  <span class="toolbar-spacer"></span>
<!--  <button type="button" aria-label="Toggle search" mat-icon-button>-->
<!--    <mat-icon aria-label="Search toggle icon">search</mat-icon>-->
<!--  </button>-->
  <button mat-icon-button [matMenuTriggerFor]="addmenu" aria-label="Create new meeting or task">
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>
<div class="app-content">
  <router-outlet></router-outlet>
</div>
