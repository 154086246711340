import { ElementRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { timer, Subscription } from 'rxjs';
import { ProgressContainerComponent } from './progress-container/progress-container.component';
import { DynamicOverlay } from './overlay/dynamic-overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';

export declare type ProgressRef = { subscription: Subscription, overlayRef: OverlayRef };

@Injectable()
export class ProgressService {

  constructor(private dynamicOverlay: DynamicOverlay) { }

  public showProgress(elRef: ElementRef) {
    if (elRef) {
      const result: ProgressRef = { subscription: null, overlayRef: null };
      result.subscription = timer(500)
        .subscribe(() => {
          this.dynamicOverlay.setContainerElement(elRef.nativeElement);
          const positionStrategy = this.dynamicOverlay.position().global().centerHorizontally().centerVertically();
          result.overlayRef = this.dynamicOverlay.create({
            positionStrategy: positionStrategy,
            hasBackdrop: true
          });
          result.overlayRef.attach(new ComponentPortal(ProgressContainerComponent));
        });
      return result;
    } else {
      return null;
    }
  }

  detach(result: ProgressRef) {
    if (result) {
      result.subscription.unsubscribe();
      if (result.overlayRef) {
        result.overlayRef.detach();
      }
    }
  }
/*
  showGlobalOverlay() {
    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true});
    overlayRef.attach(new ComponentPortal(ProgressContainerComponent))
    setTimeout(()=>overlayRef.detach(),2000);
  }

  showSelf() {
    const progressRef = this.service.showProgress(this.elRef);
    setTimeout(()=>this.service.detach(progressRef),2000);
  }
*/
}

